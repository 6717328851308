import {ElNotification} from "element-plus";
import {setTokenTime} from "@/utils/auth";

let gServer = null
class CWebSocketServer {
  constructor () {
    this.m_server = null
    this.ip = null
    this.m_cb = null
    this.m_cbEvent = null
    this.m_cbEvent_Conn = 1
    this.m_cbEvent_DisConn = 0
    this.reset_connect = null;
  }

  Connect (ServerIP) {
    const url = 'ws://' + ServerIP + '/server/websocket'
    this.ip = ServerIP;
    if ('WebSocket' in window) {
      this.m_server = new WebSocket(url)
    }

    this.m_server.onerror = function () {
      // ElNotification({
      //   title: 'websocket',
      //   message: '服务器连接错误',
      //   type: 'error',
      // })
      console.log("服务器连接错误")
      /*重连机制*/
      if (!gServer.reset_connect){
        gServer.reset_connect = window.setInterval(()=>{
          gServer.Connect(gServer.ip);
        },10000);
      }
    }

    // eslint-disable-next-line no-unused-vars
    this.m_server.onopen = function (event) {
      if (gServer.reset_connect){
        window.clearInterval(gServer.reset_connect);
        gServer.reset_connect = null;
      }
      // ElNotification({
      //   title: 'websocket',
      //   message: '服务器连接成功',
      //   type: 'success',
      // })
      console.log("服务器连接成功")
      gServer.SendEvt(gServer.m_cbEvent_Conn)
    }

    this.m_server.onmessage = function (event) {
      gServer.MsgProc(event.data)
    }

    this.m_server.onclose = function () {
      // ElNotification({
      //   title: 'websocket',
      //   message: '服务器连接断开',
      //   type: 'warning',
      // })
      console.log("服务器连接断开")
      gServer.SendEvt(gServer.m_cbEvent_DisConn)
      /*重连机制*/
      if (!gServer.reset_connect){
        gServer.reset_connect = window.setInterval(()=>{
          gServer.Connect(gServer.ip);
        },10000);
      }
    }
    window.onbeforeunload = function () {
      gServer.DisConnect()
    }
  }

  DisConnect () {
    if ((this.m_server != null) && (this.m_server.readyState === 1)) {
      this.m_server.close()
    }
  }

  SendMsg (msg) {
    if ((this.m_server == null) || (this.m_server.readyState !== 1)) {
      return false
    }
    if (msg == null || msg === undefined) {
      return false
    }
    this.m_server.send(msg)
    setTokenTime();
    return true
  }

  MsgProc (data) {
    if (data&&data.MsgType&&data.MsgType=="Notify"){
      /*接收到通知类消息*/
      const msgModule = data.MsgModule;
      if (msgModule==="Call"){
        const msgId = data.MsgId;
        if (msgId==="StateChange"){
          const callStatusValue = data.Data.CallStatusValue;
          if (callStatusValue==="8"){
            ElNotification({
              title: '通话状态',
              message: '[TID='+data.TransId+']通话已接通',
              type: 'success',
            })
          }else if (callStatusValue==="14"){
            ElNotification({
              title: '通话状态',
              message: '[TID='+data.TransId+']通话已结束',
              type: 'success',
            })
          }
        }
      }
      return;
    }else if (data&&data.MsgType&&data.MsgType=="Rsp"){
      /*接收到操作失败类消息*/
      const rspCode = data.RspCode;
      const rspCodeModuleDesc = data.RspCodeModuleDesc;
      if (rspCode!=null&&rspCode!=undefined&&rspCode!=='0'){
        ElNotification({
          title: '请求响应',
          message: '[TID='+data.TransId+']请求失败,Desc=['+rspCodeModuleDesc+']',
          type: 'error',
        })
        return;
      }
    }
    if ((this.m_cb != null)) {
      this.m_cb(data)
    }
  }

  SetCb (cb) {
    this.m_cb = cb
  }

  SetCbEvt (cb) {
    this.m_cbEvent = cb
  }

  SendEvt (evt) {
    if ((this.m_cbEvent != null)) {
      this.m_cbEvent(evt)
    }
  }
}

const gCWebSocketServer = new CWebSocketServer()
gServer = gCWebSocketServer
export default gCWebSocketServer
