<template>
    <div id="app">
      <router-view></router-view>
    </div>
</template>

<script>
import gCWebSocketServer from "@/utils/websocket";
import gCMsg from "@/utils/msg";

export default {
    name: 'App',
    setup() {
      const debounce = (callback, delay) => {
        let tid;
        return function (...args) {
          const ctx = self;
          tid && clearTimeout(tid);
          tid = setTimeout(() => {
            callback.apply(ctx, args);
          }, delay);
        };
      };

      const _ = (window).ResizeObserver;
      (window).ResizeObserver = class ResizeObserver extends _ {
        constructor(callback) {
          callback = debounce(callback, 20);
          super(callback);
        }
      };
    },
    data(){
      return {
          serverip: '127.0.0.1',
          websocketState: '未连接',
      }
    },
    mounted() {
        this.serverip = window.location.hostname
        gCWebSocketServer.SetCbEvt(this.WebSocketEvt)
        gCWebSocketServer.SetCb(gCMsg.MsgProc)
        gCWebSocketServer.Connect(this.serverip)
    },
    watch: {
      // eslint-disable-next-line no-unused-vars
      '$route' (to, from) {
        document.title = to.meta.title || 'voip'
      }
    },
    methods: {
        WebSocketEvt (evt) {
            if (evt === gCWebSocketServer.m_cbEvent_Conn) {
                this.websocketState = '已连接'
            } else if (evt === gCWebSocketServer.m_cbEvent_DisConn) {
                this.websocketState = '未连接'
            }
        }
    },
    components: {

    }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
