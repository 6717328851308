export default {
    login: "登录",
    save: "保存",
    cancel: "取消",
    upload: "上传",
    userName: "用户名",
    passWord: "密码",
    rePassWord: "再输一次密码",
    suggest: "建议使用chrome浏览器",
    uploadDesc: "固件更新过程中不能断电",
    enable: "启用",
    close: "关闭",
    forbidden: "禁用",
    title: {
        systemStatus: "系统状态",
        networkSetting: "网络设置",
        sipSetting: "SIP设置",
        callSetting: "通话设置",
        telephoneSetting: "电话设置",
        entranceGuardSetting: "门禁设置",
        seniorManagement: "高级管理",
        userAccount: "用户账户",
        logout: "退出&注销",
    },
    subTitle: {
        systemStatus: "状态",
        networkSetting: "网络设置",
        sipSetting: "SIP设置",
        callSetting: "通话设置",
        telephoneSetting: "电话设置",
        entranceGuardSetting: "门禁输入输出设置",
        seniorManagement: "高级管理",
        userAccount: "登陆账号",
    },
    common: {
        advancedSetup: "高级设置",
        input1: "输入1",
        keyboardM1: "键盘M1",
        keyboardM2: "键盘M2",
        selectFile: "选择文件",
    },
    callSetting: {
        base: "基本设置",
        groupCall: "群呼",
        multicast: "组播",
        telephoneBook: "电话本",
        alarmNumber: "报警号码",
        manualAlarm: "手动报警",
        localBroadcast: "本机广播",
        autoAnswer: "自动应答",
        hotLine: "热线",
        speedDial1: "速拨1",
        speedDial2: "速拨2",
        whetherToEnableGroupCall: "是否启用群呼",
        triggerMode: "触发方式",
        whetherToEnableMulticast: "是否启用组播",
        triggerSelect: "发起组播触发选择",
        whetherToEnableBroadcast: "是否启用广播",
        whetherToPlayRecordedAudio: "是否播放录制音频",
        whetherToPlayRecordedAudioDesc: "音频格式 8000Hz Mono",
        playCycles: "播放循环次数",
        playCyclesDesc: "0为不限次数,直到手动停止为止",
        audioFile: "音频文件",
        enableAlarm: "是否启用报警号码功能",
        enableAlarmDesc: "如果启用此项,改设置优先级高于电话设置-LED功能定义",
        alarmNumberDetected: "检测到报警号码",
        ordinaryCall: "普通呼叫",
        l1l21: "来电LED为1,LED2为1",
        l1l20: "来电LED为1,LED2为0",
        l0l21: "来电LED为0,LED2为1",
        l0l20: "来电LED为0,LED2为0",
        enableManualAlarm: "是否启用手动报警功能",
        enableManualAlarmDesc: "如果启用此项,可以在触发局域网内所有对讲发起警报",
        callNumber: "呼叫号码",
        alarmTriggeringMode: "报警触发方式",
        Input1: "输入口1触发",
        KB: "按键指令触发(*58触发 *59结束)",
        Input1KB: "输入口1触发+按键指令触发",
        VoiceId: "报警音",
        ID: "报警识别码",
        LocalCastEnable: "是否启用本机广播",
        triggerBroadcastSelection: "触发广播选择",
        whetherToEnableTheAlarmLight: "是否启用报警灯",
        firstLanguageCoding: "第一语言编码",
        secondLanguageCoding: "第二语言编码",
        thirdLanguageCoding: "第三语言编码",
        fourthLanguageCoding: "第四语言编码",
    },
    networkSetting: {
        wiredNetwork: "有线网络",
        IPAlocType: "网络协议",
        IPv4: "IP地址",
        IPv4Mask: "Netmask",
        Gw: "网关",
        DNS: "DNS",
        NTPAddr: "时间服务器",
        DataTime: "日期时间",
    },
    sipSetting: {
        sipAccountInformation: "SIP账号信息",
        RecordKey: "选择SIP",
        Enable: "本账号是否生效",
        RegEnable: "是否发起注册",
        RegExpires: "注册申请时长",
        Username: "账号1",
        Password: "密码",
        DomainPort: "服务器地址:端口",
        AgentServerPort: "代理服务器:端口",
        NAT: "是否过NAT",
        NATDesc: "NAT穿越,选择启用,不选不启用,建议不选",
        ErrReRegVal: "错误重新注册间隔",
        ErrReRegValDesc: "408 480 500 502 503 504 6xx错误时重新注册间隔",
        sipRegInterval: "SIP注册保持间隔",
        sipMonitorInterval: "SIP注册监测间隔",
        sipMonitorIntervalDesc: "0~60s, 0 关闭此功能,需要重启生效",
        TcpHeartBeatVal: "TCP心跳包间隔",
        TcpHeartBeatValDesc: "0~500s, 默认15",
        SessionUpdateEnable: "会话更新",
        SessionUpdateSecDesc: "最小值 90",
        TransType: "传输类型",
        TransTypeDesc: "修改此项,需要重启才能生效",
        UDPPort: "UDP端口",
        UDPPortDesc: "0 自动调整随机端口",
        RTPPort: "RTP端口",
        RTPPortDesc: "0~65535 建议值10000, 0则自动调整",
        RTPPortSection: "RTP端口范围",
        RTPPortSectionDesc: "0~1000 建议值32, 0则自动调整",
        ForbitRport: "禁用Rport",
        relFlagDesc: "选中启用, 不选则不启用, 建议不选",
    },
    telephoneSetting: {
        volumeSetting: "音量设置",
        functionKeyDefinition: "功能键定义",
        ledFunctionDefinition: "LED功能定义",
        speakerphoneVolume:"免提扬声器音量",
        handsFreeMicrophoneDeliveryVolume:"免提麦克风送话音量",
        handsetSpeakerVolume:"听筒扬声器音量",
        volumeOfMicrophoneDelivery:"免提扬声器音量",
        transmitGain:"发送增益(麦克风)",
        receiveGain:"接收增益(扬声器)",
        theFirstFunctionKey: "第一个功能键",
        secondFunctionKey: "第二个功能键",
        theThirdFunctionKey: "第三个功能键",
        theFourthFunctionKey: "第四个功能键",
        quickFlick: "速拨",
        rebroadcast: "重播",
        flash: "闪断",
        hf: "免提",
        setSpeedDial1: "速拨1设置",
        setSpeedDial2: "速拨2设置",
        selfResettingTrigger: "自复位式触发",
        selfResettingTriggerDesc: "自复位式触发,一般接自复位式按钮,只需短按一下触发",
        selfLockingTrigger: "自锁式触发",
        selfLockingTriggerDesc: "自锁式触发,一般接自锁按钮,需要一直保持闭合状态才能保持触发",
        registrationOnlyEnabled: "是否注册才启用",
        led1FunctionDefinition: "LED1功能定义",
        led2FunctionDefinition: "LED2功能定义",
        lcdBacklightDefinition: "LCD背光定义",
        keyboardBacklightDefinition: "键盘背光定义",
        OffHookOn: "摘机亮",
        CallActive: "通话指示灯",
        NoAnswer: "未接电话指示灯",
        StanbyY_ActiveYN: "待机常亮 通话闪烁",
        StanbyN_ActiveY: "待机灭 通话常亮",
        StanbyYN_ActiveY: "待机闪烁 通话常亮",
        Hook: "挂钩",
        AllY: "常亮",
        keyboardFunctionKey3: "键盘功能键3",
        keyboardFunctionKey4: "键盘功能键4",
        silenceDetection: "静音检测",
        silenceDetectionDesc: "EC算法为3, 771, 899时有效",
        ecAlgorithm: "EC算法",
        algorithmState: "算法状态",
        theCallingPartyStartsBroadcasting: "主叫启动广播",
        theCallingPartyStartsBroadcastingDesc: "主叫启动广播 0关闭 1打开",
        calledStartBroadcast: "被叫启动广播",
        calledStartBroadcastDesc: "被叫启动广播 0关闭 1打开",
        enabledWhenThePhoneRings: "振铃时启用",
        activateWhenTheCallIsConnected: "电话接通后启动",
        micMuteCalledParty: "被叫MIC静音",
        micMuteCalledParty1: "被叫时MIC静音,听筒摘机后解除静音",
        talkTimeLimit: "通话时间限制",
        talkTimeLimitDesc: "0~30分钟",
        promptToneBeforeACall: "呼前提示音",
        noInterruptionsAllowed: "通话中不允许打断",
        afterBootingPlayIP: "开机后播放IP地址",
        automaticRestartInterval: "自动重启间隔",
        automaticRestartIntervalDesc: "单位: 小时",
        whetherToForciblyRestart: "是否强制重启",
        whetherToForciblyRestartDesc: "到达设定时间后,不管是否在通话中,都将强制重启",
        enableTheHardwareWatchdog: "是否开启硬件看门狗",
        enableTheHardwareWatchdogDesc: "选中开启看门狗",
    },
    entranceGuardSetting: {
        inputPortFunctionDefinition: "输入口功能定义",
        quickFlick: "速拨",
        broadcast: "广播",
        callThePolice: "报警",
        onHook: "挂机",
        localBroadcast: "本地广播",
        groupBusy: "群呼",
        relay: "继电器",
        redefineTheHookFunctionality: "重定义Hook功能",
        speedDialNotEnabled: "速拨(未启用)",
        information: "消息",
        hookFunction: "hook功能",
        redefineHFFunctionality: "重定义HF功能",
        hf: "免提",
        outputFunctionDefinition: "输出口功能定义",
        callOutputCallEndStopped: "来电输出,通话结束停止",
        missedCallOutput: "未接电话输出",
        callOutputConnectedStop: "来电输出,接通停止",
        emergencyDialingOnEndOfCallOff: "紧急拨号打开,通话结束关闭",
        dtmfRemoteControl: "DTMF远程控制",
        outputHoldTime: "输出保持时间",
        alwaysKeep: "始终保持",
        relayFunction: "继电器功能",
        offHookTrigger: "摘机触发",
        triggered: "*触发",
        triggered1: "#触发",
        triggered2: "密码触发",
        triggered3: "来电触发,接通停止",
        triggered4: "广播触发,结束停止",
        triggered5: "接受消息",
        relayHoldingTime: "继电器保持时间",
    },
    seniorManagement: {
        configurationFiles: "配置文件",
        rework: "重启/恢复出厂设置",
        firmwareUpload: "固件上传",
        log: "日志/通话记录",
        debug: "调试",
        webDialing: "网页拨号",
        ping: "Ping",
        uploadConfigurationFile: "上传配置文件",
        downloadConfigurationFile: "下载配置文件",
        downloadConfigurationFileBtn: "点击下载配置文件",
        factoryDataReset: "恢复出厂设置",
        factoryDataResetBtn: "点击恢复出厂设置",
        restart: "重启",
        restartBtn: "点击重启",
        clearWave: "清空wave",
        clearWaveBtn: "点击清空wave文件",
        selectiveFirmware: "选择固件",
        callHistoryFileSize: "通话记录文件大小",
        downloadLog: "下载日志",
        clearLog: "清空日志",
        downloadCallLog: "下载通话记录",
        clearCallHistory: "清空通话记录",
        networkPacketCapture: "网络抓包",
        startPacketCapture: "开始抓包",
        stop: "停止",
        pcmAudioDataCapture: "PCM音频数据抓包",
        startToCapturePCMData: "开始抓PCM数据",
        stopRecording: "停止记录",
        phoneNumberIPAddress: "电话号码/IP地址",
        dial: "拨号",
        onHook: "挂机",
        handsFreeMode: "免提模式",
        handsetMode: "听筒模式",
        ipAddress: "Ip地址",
    },
    userAccount: {
        accountInformation: "账号信息",
    },
    messages: {
        nullAccount: '请输入账号',
        nullUserName: '请输入登录用户名',
        nullPassword: '请输入密码',
        nullRePassword: '请再次输入密码',
        nullNoEqPassword: '两次输入的密码不一致,请重新输入',
        saveTitle: "数据提交",
        saveSuccess: "保存成功",
        nullIPv4: "IP地址不能为空",
        illegalityIPv4: "IP地址格式错误",
        nullIPv4Mask: "Netmask不能为空",
        illegalityIPv4Mask: "Netmask格式错误",
        nullGw: "网关地址不能为空",
        illegalityGw: "网关地址格式错误",
        nullDNS: "DNS地址不能为空",
        illegalityDNS: "DNS地址格式错误",
        nullDataTime: "日期时间不能为空",
        nullINTPAddr: "时间服务器不能为空",
        illegalityNTPAddr: "时间服务器地址格式错误",
        serverIp: "服务器地址格式错误",
        serverPort: "服务器端口格式错误[0-65535]",
        agentIp: "代理服务器地址格式错误",
        agentPort: "代理服务器端口格式错误",
        regExpires: "请输入注册申请时长[60-36000]",
        errReRegVal: "请输入错误重新注册间隔",
        tcpHeartBeatVal: "请输入TCP心跳包间隔[0-500s],默认15",
        sessionUpdateSec: "请输入会话更新时长[最小值90]",
        UDPPort: "UDP端口格式错误[0-65535]",
        RTPPort: "RTP端口格式错误[0-65535]",
        RTPPortSection: "RTP端口范围[0-1000]",
        outgain: "发送增益(麦克风)[0.0-9.9]",
        ingain: "接收增益(扬声器)[0.0-9.9]",
    }
};
