const TokenKey = 'token'
const TokenTime = 'token_time'
const TokenOutTime = 600000;    //10分钟
var TokenOutObj = null;    //10分钟

export function random(size) {
    let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
    let maxPos = chars.length;
    var code = '';
    for (let i = 0; i < size; i++) {
        code += chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return code;
}

export function checkIfUserIsLoggedIn(){
    let userInfo = localStorage.getItem(TokenKey);
    let time = localStorage.getItem(TokenTime);
    return userInfo&&time;
}
export function checkLoginTime(app){
    TokenOutObj = window.setInterval(()=>{
        let currTime = new Date().getTime();
        let time = localStorage.getItem(TokenTime);
        if (currTime-time>TokenOutTime){
            removeToken();
            app.$router.push({ path: app.redirect || "/login" }).catch(()=>{});
            window.clearInterval(TokenOutObj);
        }
    },5000);

}
export function setToken(token){
    setTokenTime();
    return localStorage.setItem(TokenKey,token)
}
export function setTokenTime(){
    return localStorage.setItem(TokenTime,new Date().getTime())
}
export function getToken(){
    return localStorage.getItem(TokenKey)
}
export function removeToken(){
    localStorage.removeItem(TokenTime);
    return localStorage.removeItem(TokenKey)
}
