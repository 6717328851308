import {ElMessage} from "element-plus";
import axios from "axios";

class CTool {
  // eslint-disable-next-line no-useless-constructor
  constructor () {

  }
  IsIp (value) {
    const regexp = /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/
    const valid = regexp.test(value)
    if (!valid) { // 首先必须是 xxx.xxx.xxx.xxx 类型的数字，如果不是，返回false
      return false
    }

    let list = value.split('.')
    for (let i = 0; i < list.length; i++) {
      let num = list[i]
      if (num.length > 1 && num.charAt(0) === '0') {
        // 大于1位的，开头都不可以是‘0’
        return false
      } else if (parseInt(num, 10) > 255) {
        // 大于255的不能通过
        return false
      }
      return true
    }
  }

  IsNaturalNumber (value) {
    const regexp = /^\d+$/
    const valid = regexp.test(value)
    if (valid) {
      return true
    } else {
      return false
    }
  }

  Trim (str) {
    return str.replace(/(^\s*)|(\s*$)/g, '')
  }
  verification (value,type,msg) {
    if (type=="ip"){
      if (!this.IsIp(value)){
        ElMessage({
          message: msg,
          type: 'warning',
        })
        return true;
      }
    }if (type=="port"){
      if ((!this.InputDigitProc(value)) || value< 0 || value > 65535){
        ElMessage({
          message: msg,
          type: 'warning',
        })
        return true;
      }
    }else if (type=="number"){
      if (!this.IsNaturalNumber(value)){
        ElMessage({
          message: msg,
          type: 'warning',
        })
        return true;
      }
    }else if (type=="int"){
      if (!this.InputDigitProc(value)){
        ElMessage({
          message: msg,
          type: 'warning',
        })
        return true;
      }
    }else if (type=="null"){
      if (value==null||value==undefined||value=="null"||!this.Trim(value+"")){
        ElMessage({
          message: msg,
          type: 'warning',
        })
        return true;
      }
    }
  }

  GetCurDate () {
    const now = new Date()
    let year = now.getFullYear().toString()

    let mon = (now.getMonth() + 1).toString()
    if (mon.length === 1) {
      mon = '0' + mon
    }
    let day = now.getDate().toString()
    if (day.length === 1) {
      day = '0' + day
    }
    let date = year + '-' + mon + '-' + day
    return date
  }

  GetCurTime () {
    const now = new Date()
    let hour = now.getHours().toString()
    if (hour.length === 1) {
      hour = '0' + hour
    }
    let min = now.getMinutes().toString()
    if (min.length === 1) {
      min = '0' + min
    }
    let sec = now.getSeconds().toString()
    if (sec.length === 1) {
      sec = '0' + sec
    }
    let time = hour + ':' + min + ':' + sec
    return time
  }

  GotoNewUrl (url, delayms) {
    setTimeout(window.open, delayms, url, '_blank')
  }

  InputDigitProc (value) {
    return /^[0-9]*$/.test(parseInt(value)) ? String(parseInt(value)).replace('.', '') : ''
  }
}

const gCTool = new CTool()
export default gCTool

/**
 *
 * @param url 目标下载接口
 * @param query 查询参数
 * @param fileName 文件名称
 * @returns {*}
 */
export function downBlobFile(url, query, fileName) {
  return axios.request({
    url: url,
    method: 'get',
    responseType: 'blob',
    params: query,
  }).then((response) => {
    handleBlobFile(response, fileName);
  }).catch((error)=>{
    ElMessage.error(error.message);
  });
}

/**
 * blob 文件刘处理
 * @param response 响应结果
 * @returns
 */
export function handleBlobFile(response, fileName) {
  // 处理返回的文件流
  const blob = response;
  if (blob && blob.size === 0) {
    ElMessage.error('内容为空，无法下载');
    return;
  }
  const link = document.createElement('a');

  // 兼容一下 入参不是 File Blob 类型情况
  var binaryData = [];
  binaryData.push(response);
  link.href = window.URL.createObjectURL(new Blob(binaryData));
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  window.setTimeout(function () {
    URL.revokeObjectURL(blob);
    document.body.removeChild(link);
  }, 0);
}
