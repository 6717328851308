import {createRouter, createWebHashHistory} from 'vue-router'
import { checkIfUserIsLoggedIn } from '@/utils/auth'
const noAuthPath = "/login";

const router = createRouter({
    history: createWebHashHistory(),
    routes:[
        {
            path:'/',
            component:()=> import('@/components/console/index'),
            meta: {
                auth: true,
                title: 'VOIP - Intercom',
            }
        },
        {
            path:'/login',
            component:()=> import('@/components/login/index'),
            meta: {
                auth: false,
                title: 'Login',
            }
        },
        {
            path:'/callSetting',
            component:()=> import('@/components/callSetting/index'),
            meta: {
                auth: true,
                title: 'Call Set',
            }
        },
        {
            path:'/entranceGuardSetting',
            component:()=> import('@/components/entranceGuardSetting/index'),
            meta: {
                auth: true,
                title: 'Intercom',
            }
        },
        {
            path:'/networkSetting',
            component:()=> import('@/components/networkSetting/index'),
            meta: {
                auth: true,
                title: 'Set Network',
            }
        },
        {
            path:'/seniorManagement',
            component:()=> import('@/components/seniorManagement/index'),
            meta: {
                auth: true,
                title: 'Management',
            }
        },
        {
            path:'/sipSetting',
            component:()=> import('@/components/sipSetting/index'),
            meta: {
                auth: true,
                title: 'SIP Account',
            }
        },
        {
            path:'/telephoneSetting',
            component:()=> import('@/components/telephoneSetting/index'),
            meta: {
                auth: true,
                title: 'Set Telephone',
            }
        },
        {
            path:'/userAccount',
            component:()=> import('@/components/userAccount/index'),
            meta: {
                auth: true,
                title: 'User Account',
            }
        },
        {
            path:'/:pathMatch(.*)*',
            meta: {
                auth: false,
                title: '404-页面不存在',
            },
            component:()=> import('@/components/error/404')
        },
    ],
})
router.beforeEach((to, from, next) => {
    // if (to.fullPath==="/"){
    //     to.query["bodyClass"] = "console";
    // }
    if (to.meta&&to.meta.auth) {
        const isLoggedIn = checkIfUserIsLoggedIn() // 根据业务逻辑判断用户是否已经登录
        if (!isLoggedIn) {
            next(noAuthPath) // 未登录时重定向到登录页面
        } else {
            next() // 已登录则正常跳转到目标页面
        }
    } else {
        next() // 非首页不需要登录校验，直接跳转
    }
})
export default router
