import { createApp } from 'vue'
import App from './App.vue'

/*UI样式*/
import ElementPlus from 'element-plus'
import VueLazyload from 'vue-lazyload'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import 'element-plus/dist/index.css'
import Avue from '@smallwei/avue';
import '@smallwei/avue/lib/index.css';
import '@/css/main.css'
import 'font-awesome/css/font-awesome.min.css';
import 'element-plus/theme-chalk/display.css'
import i18n from "./locales/index";

/*请求*/
import axios from 'axios'
import gCWebSocketServer from '@/utils/websocket'

/*路由*/
import router from '@/router/index.js'

const app = createApp(App,{
    axios: axios,
    webSocket: gCWebSocketServer,
})
app.use(ElementPlus,{
    locale: zhCn,
})
app.use(Avue);
app.use(VueLazyload)
app.use(router)
app.use(i18n)

app.config.productionTip = false
app.mount('#app')
