import gCWebSocketServer from './websocket'
import gCTool from "@/utils/tool";
let gMsg = null
// eslint-disable-next-line no-unused-vars
function blobToString(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(blob);
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
  });
}
class CMsg {
  constructor () {
    this.m_name_TransId = 'TransId'
    this.m_name_MsgType = 'MsgType'
    this.m_name_MsgModule = 'MsgModule'
    this.m_name_MsgId = 'MsgId'
    this.m_name_RspCode = 'RspCode'
    this.m_name_RspCodeModuleDesc = 'RspCodeModuleDesc'
    this.m_name_Data = 'Data'

    this.m_name_Req = 'Req'
    this.m_name_Rsp = 'Rsp'

    this.m_RspCode_succ = '0'
    this.m_cur_TransId = 0

    this.m_MapCb = {}

    this.m_Cur_ms = null
    this.m_SendMsg_val_min = 100
  }

  CreateReqMsg (MsgModule, MsgId, Data) {
    let strMap = {}
    this.m_cur_TransId++
    let strTransId = this.m_cur_TransId.toString()
    strMap[this.m_name_TransId] = strTransId
    strMap[this.m_name_MsgType] = this.m_name_Req
    strMap[this.m_name_MsgModule] = MsgModule
    strMap[this.m_name_MsgId] = MsgId
    strMap[this.m_name_Data] = Data
    return strMap
  }

  RspCodeIsSuccess (msg) {
    let RspCode = msg[this.m_name_RspCode]
    if (RspCode == null || RspCode === undefined || RspCode !== this.m_RspCode_succ) {
      return false
    } else {
      return true
    }
  }

  GetMsgModule (msg) {
    let MsgModule = msg[this.m_name_MsgModule]
    return MsgModule
  }

  GetMsgId (msg) {
    let MsgId = msg[this.m_name_MsgId]
    return MsgId
  }
  GetData (msg) {
    let MsgData = msg[this.m_name_Data]
    return MsgData
  }

  GetDataKey (data) {
    let DataKey = data[this.m_name_DataKey]
    return DataKey
  }

  MsgProc (msg) {
    // blobToString(msg).then((result) => {
    //   let obj = JSON.parse(result)
    //   let RspCode = gMsg.RspCodeIsSuccess(obj)
    //   if (RspCode !== true) {
    //     return
    //   }
    //
    //   let MsgModule = gMsg.GetMsgModule(obj)
    //   if (MsgModule == null || MsgModule === undefined) {
    //     return
    //   }
    //
    //   let MsgId = gMsg.GetMsgId(obj)
    //   if (MsgId == null || MsgId === undefined) {
    //     return
    //   }
    //
    //   let cb = gMsg.m_MapCb[MsgModule + MsgId]
    //   if (cb == null || cb === undefined) {
    //     return
    //   }
    //
    //   let MsgData = gMsg.GetData(obj)
    //   cb(MsgData)
    // });

    let obj = JSON.parse(msg)
    let RspCode = gMsg.RspCodeIsSuccess(obj)
    if (RspCode !== true) {
      return
    }

    let MsgModule = gMsg.GetMsgModule(obj)
    if (MsgModule == null || MsgModule === undefined) {
      return
    }

    let MsgId = gMsg.GetMsgId(obj)
    if (MsgId == null || MsgId === undefined) {
      return
    }

    let cb = gMsg.m_MapCb[MsgModule + MsgId]
    if (cb == null || cb === undefined) {
      return
    }
    let MsgData = gMsg.GetData(obj)
    cb(MsgData)
  }

  sleep (ms) {
    // eslint-disable-next-line camelcase
    const runtime_ms = new Date().getTime()
    // eslint-disable-next-line camelcase,no-empty
    while (new Date().getTime() < runtime_ms + ms) {}
  }

  SendMsg (MsgModule, MsgId, Data) {
    if (this.m_Cur_ms !== null) {
      let now = new Date().getTime()
      if (now - this.m_Cur_ms < this.m_SendMsg_val_min) {
        gMsg.sleep(this.m_Cur_ms + this.m_SendMsg_val_min - now)
      }
    }
    let msg = gMsg.CreateReqMsg(MsgModule, MsgId, Data)
    let strMsg = JSON.stringify(msg)
    gMsg.m_Cur_ms = new Date().getTime()
    let ret = gCWebSocketServer.SendMsg(strMsg)
    return ret
  }

  SetCb (MsgModule, MsgId, cb) {
    gMsg.m_MapCb[MsgModule + MsgId] = cb
  }

  SendMsgEx (MsgModule, MsgId, Data) {
    if (Data.DataKey){
      Object.keys(Data.DataKey).forEach((key)=>{
        if (typeof Data.DataKey[key] === "number"){
          Data.DataKey[key] = Data.DataKey[key]+'';
        }
        if (typeof Data.DataKey[key] === "string"){
          Data.DataKey[key] = gCTool.Trim(Data.DataKey[key]);
        }
      })
    }
    let ret = this.SendMsg(MsgModule, MsgId, Data)
    if (ret === false) {
      setTimeout(this.SendMsg, 1000, MsgModule, MsgId, Data)
    }
    return ret
  }
}

const gCMsg = new CMsg()
gMsg = gCMsg
export default gCMsg
