export default {
    login: "Login",
    save: "Save",
    cancel: "Cancel",
    upload: "Uploading",
    userName: "UserName",
    passWord: "PassWord",
    rePassWord: "EnterThePasswordAgain",
    suggest: "Chrome Is Recommended",
    uploadDesc: "do not power off during firmware update",
    enable: "enable",
    close: "close",
    forbidden: "forbidden",
    title: {
        systemStatus: "SyStem Status",
        networkSetting: "Network Settings",
        sipSetting: "SIP Settings",
        callSetting: "Call setup",
        telephoneSetting: "Telephone setup",
        entranceGuardSetting: "Access Setting",
        seniorManagement: "Senior Management",
        userAccount: "User account",
        logout: "Exit & Logout",
    },
    subTitle: {
        systemStatus: "Status",
        networkSetting: "Network Settings",
        sipSetting: "SIP Settings",
        callSetting: "Call setup",
        telephoneSetting: "Telephone setup",
        entranceGuardSetting: "Access control input and output Settings",
        seniorManagement: "Senior Management",
        userAccount: "Login Account",
    },
    common: {
        advancedSetup: "Advanced Setup",
        input1: "input 1",
        keyboardM1: "keyboard M1",
        keyboardM2: "keyboard M2",
        selectFile: "Select File",
    },
    callSetting: {
        base: "BasicSetting",
        groupCall: "GroupCalling",
        multicast: "Multicast",
        telephoneBook: "TelephoneBook",
        alarmNumber: "AlarmNumber",
        manualAlarm: "ManualAlarm",
        localBroadcast: "LocalBroadcast",
        autoAnswer: "AutoAnswer",
        hotLine: "HotLine",
        speedDial1: "SpeedDial1",
        speedDial2: "SpeedBoost2",
        whetherToEnableGroupCall: "WhetherToEnableGroupCall",
        triggerMode: "TriggerMode",
        whetherToEnableMulticast: "WhetherToEnableMulticast",
        triggerSelect: "MulticastTriggerSelection",
        whetherToEnableBroadcast: "WhetherToEnableBroadcast",
        whetherToPlayRecordedAudio: "WhetherToPlayRecordedAudio",
        whetherToPlayRecordedAudioDesc: "AudioFormat 8000Hz Mono",
        playCycles: "PlayCycles",
        playCyclesDesc: "0 indicates an unlimited number of times until manually stopped",
        audioFile: "AudioFile",
        enableAlarm: "EnableAlarmNumber",
        enableAlarmDesc: "If this is enabled, change the priority to higher than the phone Settings -LED function definition",
        alarmNumberDetected: "AlarmNumberDetected",
        ordinaryCall: "OrdinaryCall",
        l1l21: "Call LED is 1,LED 2 is 1",
        l1l20: "Incoming calls are LED 1 and LED 2 is 0",
        l0l21: "Incoming calls are LED 0 and LED 2 is 1",
        l0l20: "Call LED is 0,LED 2 is 0",
        enableManualAlarm: "EnableManualAlarm",
        enableManualAlarmDesc: "If this option is enabled, an alarm can be triggered for all intercom in the LAN",
        callNumber: "CallNumber",
        alarmTriggeringMode: "AlarmTriggeringMode",
        Input1: "Input port 1 is triggered",
        KB: "Key command trigger (*58 trigger *59 end)",
        Input1KB: "Input port 1 triggers + key command triggers",
        VoiceId: "AlarmTone",
        ID: "AlarmIdentificationCode",
        LocalCastEnable: "WhetherToEnableLocalBroadcast",
        triggerBroadcastSelection: "TriggerBroadcastSelection",
        whetherToEnableTheAlarmLight: "WhetherToEnableTheAlarmLight",
        firstLanguageCoding: "FirstLanguageCoding",
        secondLanguageCoding: "SecondLanguageCoding",
        thirdLanguageCoding: "ThirdLanguageCoding",
        fourthLanguageCoding: "FourthLanguageCoding",
    },
    networkSetting: {
        wiredNetwork: "WiredNetwork",
        IPAlocType: "NetworkingProtocol",
        IPv4: "IPAddress",
        IPv4Mask: "Netmask",
        Gw: "Gateway",
        DNS: "DNS",
        NTPAddr: "TimeServer",
        DataTime: "DateTime",
    },
    sipSetting: {
        sipAccountInformation: "SIPAccountInformation",
        RecordKey: "SelectSIP",
        Enable: "WhetherThisAccountIsValid",
        RegEnable: "WhetherToInitiateRegistration",
        RegExpires: "DurationOfRegistrationApplication",
        Username: "AccountNumber1",
        Password: "Password",
        DomainPort: "ServerAddressPort",
        AgentServerPort: "ProxyServerPort",
        NAT: "NATOrNot",
        NATDesc: "NAT traversal: Enable, disable Disable, you are advised to disable",
        ErrReRegVal: "ErrorReRegistrationInterval",
        ErrReRegValDesc: "408 480 500 502 503 504 6xx Error re-registration interval",
        sipRegInterval: "KeepAnIntervalForSIPRegistration",
        sipMonitorInterval: "SIPRegistrationMonitoringInterval",
        sipMonitorIntervalDesc: "0 to 60s, 0 Disable this function and restart it to take effect",
        TcpHeartBeatVal: "TCPHeartbeatPacketInterval",
        TcpHeartBeatValDesc: "The value ranges from 0 to 500 seconds. The default value is 15",
        SessionUpdateEnable: "SessionUpdate",
        SessionUpdateSecDesc: "MinimumValue90",
        TransType: "TransportType",
        TransTypeDesc: "The modification takes effect only after the system is restarted",
        UDPPort: "UDPPort",
        UDPPortDesc: "0 AutomaticallyAdjustRandomPorts",
        RTPPort: "RTPPort",
        RTPPortDesc: "0~65535 The recommended value is 10000. If the value is 0, it is automatically adjusted",
        RTPPortSection: "RTPPortRange",
        RTPPortSectionDesc: "0~1000 32 is recommended. 0 automatically adjusts the value",
        ForbitRport: "DisableRport",
        relFlagDesc: "If Enable is selected, disable it. You are advised not to select it",
    },
    telephoneSetting: {
        volumeSetting: "VolumeSetting",
        functionKeyDefinition: "FunctionKeyDefinition",
        ledFunctionDefinition: "LEDFunctionDefinition",
        speakerphoneVolume:"SpeakerphoneVolume",
        handsFreeMicrophoneDeliveryVolume:"HandsFreeMicrophoneDeliveryVolume",
        handsetSpeakerVolume:"HandsetSpeakerVolume",
        volumeOfMicrophoneDelivery:"SpeakerphoneVolume",
        transmitGain:"TransmitGain(Microphone)",
        receiveGain:"ReceiveGain(Loudspeaker)",
        theFirstFunctionKey: "TheFirstFunctionKey",
        secondFunctionKey: "SecondFunctionKey",
        theThirdFunctionKey: "TheThirdFunctionKey",
        theFourthFunctionKey: "TheFourthFunctionKey",
        quickFlick: "quick flick",
        rebroadcast: "rebroadcast",
        flash: "flash",
        hf: "hands free",
        setSpeedDial1: "SetSpeedDial1",
        setSpeedDial2: "SetSpeedDial2",
        selfResettingTrigger: "self resetting trigger",
        selfResettingTriggerDesc: "Self-reset trigger, generally connected to the self-reset button, just click the trigger",
        selfLockingTrigger: "self locking trigger",
        selfLockingTriggerDesc: "Self-locking trigger, generally connected to the self-locking button, need to keep the closed state to keep the trigger",
        registrationOnlyEnabled: "RegistrationOnlyEnabled",
        led1FunctionDefinition: "LED1FunctionDefinition",
        led2FunctionDefinition: "LED2FunctionDefinition",
        lcdBacklightDefinition: "LCDBacklightDefinition",
        keyboardBacklightDefinition: "KeyboardBacklightDefinition",
        OffHookOn: "off hook on",
        CallActive: "talk light",
        NoAnswer: "missed call indicator",
        StanbyY_ActiveYN: "standby steady on call blinking",
        StanbyN_ActiveY: "standby off the call is steady on",
        StanbyYN_ActiveY: "standby blinking talk is steady on",
        Hook: "linkUpWith",
        AllY: "steady on",
        keyboardFunctionKey3: "keyboard function key 3",
        keyboardFunctionKey4: "keyboard function key 4",
        silenceDetection: "SilenceDetection",
        silenceDetectionDesc: "This parameter is valid when the EC algorithm is 3, 771-899",
        ecAlgorithm: "ECAlgorithm",
        algorithmState: "algorithmState",
        theCallingPartyStartsBroadcasting: "TheCallingPartyStartsBroadcasting",
        theCallingPartyStartsBroadcastingDesc: "Start broadcast for the calling party. 0 Disable 1 Enable",
        calledStartBroadcast: "CalledStartBroadcast",
        calledStartBroadcastDesc: "The called party starts broadcast. 0 is disabled. 1 is enabled",
        enabledWhenThePhoneRings: "enabled when the phone rings",
        activateWhenTheCallIsConnected: "activate when the call is connected",
        micMuteCalledParty: "MICMuteCalledParty",
        micMuteCalledParty1: "Mute the MIC when you are called, and unmute the phone after you pick up the phone",
        talkTimeLimit: "TalkTimeLimit",
        talkTimeLimitDesc: "0~30 minute",
        promptToneBeforeACall: "PromptToneBeforeACall",
        noInterruptionsAllowed: "NoInterruptionsAreAllowedDuringTheCall",
        afterBootingPlayIP: "PlayTheIPAddressAfterTheSystemStarts",
        automaticRestartInterval: "AutomaticRestartInterval",
        automaticRestartIntervalDesc: "UnitHour",
        whetherToForciblyRestart: "WhetherToForciblyRestart",
        whetherToForciblyRestartDesc: "When the set time is reached, it will be forced to restart regardless of whether it is in a call",
        enableTheHardwareWatchdog: "WhetherToEnableTheHardwareWatchdog",
        enableTheHardwareWatchdogDesc: "SelectTurnOnWatchdog",
    },
    entranceGuardSetting: {
        inputPortFunctionDefinition: "InputPortFunctionDefinition",
        quickFlick: "quick flick",
        broadcast: "broadcast",
        callThePolice: "call the police",
        onHook: "on hook",
        localBroadcast: "local broadcast",
        groupBusy: "group busy",
        relay: "relay",
        redefineTheHookFunctionality: "RedefineTheHookFunctionality",
        speedDialNotEnabled: "speed dial (not enabled)",
        information: "information",
        hookFunction: "hook function",
        redefineHFFunctionality: "RedefineHFFunctionality",
        hf: "hands free",
        outputFunctionDefinition: "OutputFunctionDefinition",
        callOutputCallEndStopped: "call output call end stopped",
        missedCallOutput: "missed call output",
        callOutputConnectedStop: "call output connected stop",
        emergencyDialingOnEndOfCallOff: "emergency dialing on end of call off",
        dtmfRemoteControl: "dtmf remote control",
        outputHoldTime: "OutputHoldTime",
        alwaysKeep: "always keep",
        relayFunction: "RelayFunction",
        offHookTrigger: "off hook trigger",
        triggered: "*trigger",
        triggered1: "#trigger",
        triggered2: "password trigger",
        triggered3: "the call is triggered and the connection stops",
        triggered4: "broadcast triggered end stop",
        triggered5: "receive a message",
        relayHoldingTime: "RelayHoldingTime",
    },
    seniorManagement: {
        configurationFiles: "ConfigurationFiles",
        rework: "RestartRestoreFactorySettings",
        firmwareUpload: "FirmwareUpload",
        log: "LogsCallRecords",
        debug: "Debugging",
        webDialing: "WebDialing",
        ping: "Ping",
        uploadConfigurationFile: "UploadConfigurationFile",
        downloadConfigurationFile: "DownloadConfigurationFile",
        downloadConfigurationFileBtn: "clickToDownloadTheConfigurationFile",
        factoryDataReset: "FactoryDataReset",
        factoryDataResetBtn: "ClickRestoreFactorySettings",
        restart: "Restart",
        restartBtn: "click to restart",
        clearWave: "ClearWave",
        clearWaveBtn: "click to empty the wave file",
        selectiveFirmware: "SelectiveFirmware",
        callHistoryFileSize: "CallHistoryFileSize",
        downloadLog: "DownloadLog",
        clearLog: "ClearLog",
        downloadCallLog: "DownloadCallLog",
        clearCallHistory: "ClearCallHistory",
        networkPacketCapture: "NetworkPacketCapture",
        startPacketCapture: "StartPacketCapture",
        stop: "Stop",
        pcmAudioDataCapture: "PCMAudioDataCapture",
        startToCapturePCMData: "StartToCapturePCMData",
        stopRecording: "StopRecording",
        phoneNumberIPAddress: "Phone number /IP address",
        dial: "dialing",
        onHook: "onHook",
        handsFreeMode: "handsFreeMode",
        handsetMode: "handsetMode",
        ipAddress: "IpAddress",
    },
    userAccount: {
        accountInformation: "AccountInformation",
    },
    message: {
        nullUserName: 'Please enter your login username',
        nullPassword: 'Please enter password',
        nullRePassword: 'Please enter your password again',
        nullNoEqPassword: 'The two passwords are different. Please re-enter them',
        saveTitle: "Data submission",
        saveSuccess: "Save successfully",
        nullIPv4: "The IP address cannot be empty",
        illegalityIPv4: "The IP address format is incorrect",
        nullIPv4Mask: "The Netmask cannot be empty",
        illegalityIPv4Mask: "The Netmask format is incorrect",
        nullGw: "The gateway address cannot be empty",
        illegalityGw: "The gateway address format is incorrect",
        nullDNS: "The DNS address cannot be empty",
        illegalityDNS: "The DNS address format is incorrect",
        nullDataTime: "The date and time cannot be empty",
        nullINTPAddr: "The time server cannot be empty",
        illegalityNTPAddr: "The time server address format is incorrect",
        serverIp: "The server address format is incorrect",
        serverPort: "The format of the server port is incorrect[0-65535]",
        agentIp: "The proxy server address format is incorrect",
        agentPort: "The format of the proxy server port is incorrect",
        regExpires: "Please enter the registration application time[60-36000]",
        errReRegVal: "Please enter the error re-registration interval",
        tcpHeartBeatVal: "Enter the TCP heartbeat packet interval[0-500s],Default 15",
        sessionUpdateSec: "Please enter the session update duration[Minimum value 90]",
        UDPPort: "The format of the UDP port is incorrect[0-65535]",
        RTPPort: "The RTP port format is incorrect[0-65535]",
        RTPPortSection: "RTP port range[0-1000]",
        outgain: "Transmit gain(loudspeaker)[0.0-9.9]",
        ingain: "Receive gain(microphone)[0.0-9.9]",
    }
};
